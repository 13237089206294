const classes = {
  defaultEmail: 'is-primary is-disabled',
  addedEmail: 'is-danger',
};

class PersonEdit {
  constructor() {
    this.formGroup = $('.js-form-group[data-prototype]');
    if (!this.formGroup) {
      return;
    }
    this.init();
  }

  init() {
    this.formGroup.find('.js-remove-item:not(:first)')
      .removeClass(classes.defaultEmail).addClass(classes.addedEmail)
      .closest('.js-form-group-item').find('label')
      .text('');

    this.handleAddItem();
    this.handleRemoveItem();
    this.nameFieldsPopulator();
  }

  nameFieldsPopulator() {
    $('.js-first-name, .js-last-name').on('keyup', () => {
      const firstName = $('.js-first-name').val();
      const lastName = $('.js-last-name').val();
      let fullName = '';

      if (lastName.length) {
        fullName = firstName + ' ' + lastName;
      }

      $('.js-full-name, .js-display-name').val(fullName);

      const email = fullName.toLowerCase().replace(/\s+/g, '.') + '@xfive.co';
      $('.js-email').first().val(email);
    });
  }

  handleAddItem() {
    this.formGroup.find('.js-add-item').click(event => {
      const template = this.formGroup.find('.js-form-group-item:first').clone();

      event.preventDefault();

      template.find('label').text('');
      template.find('input').val('');
      template.find('.js-remove-item')
        .removeClass(classes.defaultEmail)
        .addClass(classes.addedEmail);
      $(event.target).parent().before(template);
      this.fixEmailsFormAttributes();
    });
  }

  handleRemoveItem() {
    this.formGroup.on('click', '.js-remove-item', event => {
      const $target = $(event.target);
      event.preventDefault();

      $target.closest('.js-form-group-item').remove();
      this.fixEmailsFormAttributes();
    });
  }

  fixEmailsFormAttributes() {
    this.formGroup.find('.js-form-group-item').each((index, item) => {
      const $item = $(item).find('input');

      $item.attr('id', $item.attr('id').replace(/_[0-9]+_/, '_' + index + '_'));
      $item.attr('name', $item.attr('name').replace(/\[[0-9]+\]/, '[' + index + ']'));
    });
  }
}

export default PersonEdit;
