const statusMap = {
    success: 'Alive',
    pending: 'Updating',
    failed: 'Broken'
};

const statusClassMap = {
    success: 'tag is-medium is-success',
    pending: 'tag is-medium is-warning',
    failed: 'tag is-medium is-danger'
};

class ProjectShow {
  constructor() {
    this.init();
  }

  init() {
    this.$projectStatus = $('.js-project-status');
    this.$projectLogs = $('.js-project-logs');
    this.$zipUpdatedDate = $('.js-zip-updated-date');
    this.$zipDownloadLink = $('.js-zip-download-link');
    this.$zipButton = $('.js-zip-button');
    this.$serverFilesUpdatedDate = $('.js-server-files-updated-date');
    this.$serverFilesDownloadLink = $('.js-server-files-download-link');
    this.$serverFilesButton = $('.js-zip-server-files-button');
    this.$pendingAjax = false;

    if (this.$projectStatus.size() && this.$projectLogs.size()) {
      const _this = this;
      setInterval(function() {
        if (!_this.$pendingAjax) {
          _this.$pendingAjax = true;
          $.ajax({
            url: window.location.pathname + '/status',
            dataType: 'json',
            success: function (response) {
              _this.updateLogs(response);
              _this.updateZIP(response);
              _this.updateServerFiles(response);
            }
          });
        }
      }, 1000);
    }
  }

  updateLogs(response) {
    const projectLogsBody = this.$projectLogs.find('tbody');
    let l, log, logsLength = response.logs.length;

    this.$pendingAjax = false;
    const projectStatusTag = this.$projectStatus.find('span');
    projectStatusTag.removeClass();
    projectStatusTag.addClass(statusClassMap[response.status]);
    projectStatusTag.text(statusMap[response.status]);

    projectLogsBody.empty();
    for (l = 0; l < logsLength; l += 1) {
      log = response.logs[l];
      projectLogsBody.append(
        '<tr><td><a href="' + log.profile + '">' + log.author + '</td><td>' +
        (log.state === 'error' ? '<span class="tag is-danger">Error</span> ' : '') +
        log.message + '</td><td>' + log.createdDate + '</td></tr>');
    }
  }

  updateZIP(response) {
    this.updateBuild(response, 'zip', this.$zipUpdatedDate, this.$zipButton, 'Update ZIP', this.$zipDownloadLink);
  }

  updateServerFiles(response) {
    this.updateBuild(
      response, 'serverFiles', this.$serverFilesUpdatedDate, this.$serverFilesButton,
      'Update Server Files ZIP', this.$serverFilesDownloadLink
    );
  }

  updateBuild(response, type, updatedDate, button, buttonLabel, downloadLink) {
    if (!response[type + 'UpdatedDate']) {
      return;
    }

    if (response[type + 'UpdatePending']) {
      updatedDate.empty().append(
        '<span class="icon is-small"><i class="fa fa-spinner fa-spin"></i></span> Updating&hellip;'
      );
      button.attr('disabled', true);
    } else {
      button.attr('disabled', false);

      if (updatedDate) {
        updatedDate.text(response[type + 'UpdatedDate']);
        let link = response[type + 'DownloadLink']
        downloadLink.empty().append($('<a href="' + link + '">' + link + '</a>'));

        if (button.size()) {
          button.removeClass('btn-success').addClass('btn-primary').text(buttonLabel);
        }
      }
    }
  }
}

export default ProjectShow;
