/*
  Project: Fives Concept
  Author: Xfive
 */

import './Tooltips';
import Availability from './Availability';
import ConfirmAlert from './ConfirmAlert';
import CustomRadios from './CustomRadios';
import Datatables from './Datatables';
import Datepicker from './Datepicker';
import Nav from './Nav';
import Notification from './Notifications';
import PersonEdit from './PersonEdit';
import ProfileEdit from './ProfileEdit';
import ProfilePhoto from './ProfilePhoto';
import ProjectsEdit from './ProjectsEdit';
import ProjectShow from './ProjectShow';
import Slugger from './Slugger';
import Tabs from './Tabs';
import TagsInput from './TagsInput';

new Availability();
new ConfirmAlert();
new CustomRadios();
new Datatables();
new Datepicker();
new Nav();
new Notification();
new PersonEdit();
new ProfileEdit();
new ProfilePhoto();
new ProjectsEdit();
new ProjectShow();
new Slugger();
new Tabs();
new TagsInput();
