export default function customMonkeyPatching() {
  $.extend( true, $.fn.dataTable.defaults, {
    'sDom': '<\'level\'<\'level-left is-dynamic is-loading\'<\'level-item\'i><\'level-item\'f>>r<\'#custom-options-target\'>>t<\'level is-dynamic is-loading\'<\'level-left\'l><\'level-right\'p>>',
    'sPaginationType': 'custom',
    'language': {
      'info': '<h3 class="subtitle is-5"><strong>_START_ - _END_</strong> of _TOTAL_ entries</h3>',
      'infoEmpty': '<h3 class="subtitle is-5">No results</h3>',
      'infoFiltered': ''
    },
    'oLanguage': {
      'sSearch': '<div class="level-item">'+
                        '<p class="control has-addons search-data">'+
                            '_INPUT_'+
                        '</p>'+
                     '</div>',
      'sLengthMenu': '<div class="control is-horizontal"><div class="control-label"><label class="label">Entries per page</label></div>'+
                        '<span class="select"><select>'+
                            '<option value="10">10</option>'+
                            '<option value="20">20</option>'+
                            '<option value="-1">All</option>'+
                        '</select></span></div>'
    }
  } );

  $.fn.dataTableExt.oApi.fnPagingInfo = function ( oSettings )  {
    return {
      'iStart':         oSettings._iDisplayStart,
      'iEnd':           oSettings.fnDisplayEnd(),
      'iLength':        oSettings._iDisplayLength,
      'iTotal':         oSettings.fnRecordsTotal(),
      'iFilteredTotal': oSettings.fnRecordsDisplay(),
      'iPage':          Math.ceil( oSettings._iDisplayStart / oSettings._iDisplayLength ),
      'iTotalPages':    Math.ceil( oSettings.fnRecordsDisplay() / oSettings._iDisplayLength )
    };
  };

  $.extend( $.fn.dataTableExt.oPagination, {
    'custom': {
      'fnInit': function( oSettings, nPaging, fnDraw ) {
        var oLang = oSettings.oLanguage.oPaginate;
        var fnClickHandler = function ( e ) {
          e.preventDefault();
          if ( oSettings.oApi._fnPageChange(oSettings, e.data.action) ) {
            fnDraw( oSettings );
          }
        };

        $(nPaging).addClass('pagination').append(
                  '<ul>'+
                      '<li class="prev disabled"><a href="#" class="button">'+oLang.sPrevious+'</a></li>'+
                      '<li class="next disabled"><a href="#" class="button">'+oLang.sNext+'</a></li>'+
                  '</ul>'
              );
        var els = $('a', nPaging);
        $(els[0]).bind( 'click.DT', { action: 'previous' }, fnClickHandler );
        $(els[1]).bind( 'click.DT', { action: 'next' }, fnClickHandler );
      },

      'fnUpdate': function ( oSettings, fnDraw ) {
        var iListLength = 5;
        var oPaging = oSettings.oInstance.fnPagingInfo();
        var an = oSettings.aanFeatures.p;
        var i, j, iLen, sClass, iStart, iEnd, iHalf=Math.floor(iListLength/2);
        var clickCallback = function (e) {
          e.preventDefault();
          oSettings._iDisplayStart = (parseInt($('a', this).text(),10)-1) * oPaging.iLength;
          fnDraw( oSettings );
        };

        if ( oPaging.iTotalPages < iListLength) {
          iStart = 1;
          iEnd = oPaging.iTotalPages;
        }        else if ( oPaging.iPage <= iHalf ) {
          iStart = 1;
          iEnd = iListLength;
        } else if ( oPaging.iPage >= (oPaging.iTotalPages-iHalf) ) {
          iStart = oPaging.iTotalPages - iListLength + 1;
          iEnd = oPaging.iTotalPages;
        } else {
          iStart = oPaging.iPage - iHalf + 1;
          iEnd = iStart + iListLength - 1;
        }

        for (i = 0, iLen = an.length; i < iLen; i++) {
                  // Remove the middle elements
          $('li:gt(0)', an[i]).filter(':not(:last)').remove();

                  // Add the new list items and their event handlers
          for ( j = iStart ; j <= iEnd ; j++ ) {
            sClass = (j === parseInt(oPaging.iPage, 10) + 1) ? 'class="button is-primary"' : 'class="button"';
            $('<li><a href="#" '+sClass+'>'+j+'</a></li>')
                          .insertBefore( $('li:last', an[i])[0] )
                          .bind('click', clickCallback);
          }

                  // Add / remove disabled classes from the static elements
          if ( oPaging.iPage === 0 ) {
            $('li:first', an[i]).addClass('disabled');
          } else {
            $('li:first', an[i]).removeClass('disabled');
          }

          if ( oPaging.iPage === oPaging.iTotalPages-1 || oPaging.iTotalPages === 0 ) {
            $('li:last', an[i]).addClass('disabled');
          } else {
            $('li:last', an[i]).removeClass('disabled');
          }
        }
      }
    }
  } );
}
