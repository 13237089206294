class Notification {
  constructor() {
    this.notification = document.querySelector('.js-notification-container');
    this.button = document.querySelector('.js-btn-hide');

    if (!this.notification) {
      return;
    }

    this.init();
  }

  init() {
    this.hideNotification();
  }

  hideNotification() {
    this.button.addEventListener('click', () => {
      this.notification.classList.add('is-hidden');
    });
  }
}

export default Notification;
