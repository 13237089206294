class Slugger {
  constructor() {
    this.inputs = $('[data-slugger]:not(:disabled)');

    if (!this.inputs) {
      return;
    }

    this.init();
  }

  init() {
    Array.from(this.inputs).forEach((input) => {
      let source = $(input.dataset.slugger).first();
      let separator = input.dataset.sluggerSeparator;

      source.on('keyup', () => {
        input.value = this.slugify(source.val(), separator)
      });

    })
  }

  slugify(string, separator = '-') {
    return string.toString().toLowerCase()
      .replace(/\s+/g, separator)
      .replace(new RegExp(`[^\\w\\${separator}]+`, 'g'), '')
      .replace(new RegExp(`\\${separator}\\${separator}+`, 'g'), separator)
      .replace(new RegExp(`^\\${separator}+`), '')
      .replace(new RegExp(`\\${separator}+$`), '');
  }
}

export default Slugger;
