/* global $ */
// Handles tagsInput plugin
class TagsInput {
  constructor() {
    this.$googleGroupsSelect = $('.js-googlegroups');
    this.$googleGroupsInitialInputs = $('.js-googlegroups-initial-inputs');

    this.classes = {
      tagsInputContainer: 'tagsinput-container',
      tagsInput: 'tagsinput',
    }

    this.init();
  }

  init() {
    this.handleTagsInput();
  }

  preinitTagsInput() {
    $('[data-extra="' + this.classes.tagsInput + '"]').each((i, element) => {
      const $element = $(element);
      $element
        .before(`<div class="${this.classes.tagsInputContainer}"></div>`)
        .after(`<input type="text" id="${$element.attr('id')}_tagsinput" class="${this.classes.tagsInput}">`)
    });
  }

  handleTagsInput() {
    this.preinitTagsInput();
    this.initTagsInput();

    // Remove the initial Google Groups inputs because they will be recreated
    // inside .appendGoogleGroupsToContainer() method
    this.$googleGroupsInitialInputs.remove();
  }

  initTagsInput() {
    $('.' + this.classes.tagsInput).each((i, element) => {
      const $tagsInput = $(element);
      const $parent = $tagsInput.parent();
      const selectItem = $parent.find('select');
      // googlegroups is a special case of tagsinput, mind that
      const isGoogleGroups = selectItem.data('googlegroups');
      const tagsInputContainer = selectItem.parent()
        .find('.' + this.classes.tagsInputContainer)
        .empty();

      const getACOptions = () =>
        $.map($tagsInput.parent().find('option:not(:selected)'), i => $(i).text());

      const onChangeTag = () => {
        const $tags = $parent.find('.tag');

        resetOptions(selectItem);

        tagsInputContainer.empty();

        $tags.each((index, tag) =>
          this.populateTags({
            tag,
            selectItem,
            isGoogleGroups,
            tagsInputContainer,
            index
          }));

        $tagsInput.parent().find('.ui-autocomplete-input')
          .attr('placeholder', 'add a tag')
          .autocomplete('option', 'source', getACOptions())
      };

      // remove custom styling for .select
      $parent.removeClass('select');
      $parent.find('select').hide();
      $('[data-extra="' + this.classes.tagsInput + '"]').hide();

      this.setupInitialTags($tagsInput, $parent, isGoogleGroups);

      $tagsInput.tagsInput({
        width: '100%',
        height: 'auto',
        autocomplete_url: getACOptions(),
        onChange: onChangeTag,
        onAddTag: onChangeTag,
      }).removeAttr('name');

      if (isGoogleGroups) {
        this.setupInitialGoogleGroups($tagsInput);
      }
    });
  }

  populateTags({ tag, selectItem, isGoogleGroups, tagsInputContainer, index }) {
    const tagText = $(tag).find('> span').text().trim();
    const availableOption = selectItem.find('option')
      .filter((i, option) => $(option).text().trim() === tagText);
    const hasAvailableOption = availableOption.length;

    if (isGoogleGroups) {
      if (hasAvailableOption) {
        this.appendGoogleGroupsToContainer(tagsInputContainer, availableOption, index, tagText);
        index++;
      }
    } else {
      if (hasAvailableOption) {
        availableOption.prop('selected', true);
      } else {
        tagsInputContainer.append(`
          <input type="hidden" name="xrm_people_extra_skills[]" value="${tagText}">
        `);
      }
    }
  }

  appendGoogleGroupsToContainer(tagsInputContainer, availableOption, index, tagText) {
    tagsInputContainer.append(`
      <input
        type="hidden"
        name="xrm_core_basebundle_user[userGoogleGroups][${index}][googleId]"
        value="${availableOption.val()}">
      <input
        type="hidden"
        name="xrm_core_basebundle_user[userGoogleGroups][${index}][name]"
        value="${tagText}">
    `);
  }

  setupInitialTags($tagsInput, $parent) {
    $tagsInput.val(
      $.map($parent.find('select option:selected'), i => $(i).text()).join(',')
    );
  }

  // Initial Google Groups are supplied by Symfony backend. For "tagsInput"
  // we need to map them to ".tagsinput" input
  setupInitialGoogleGroups($tagsInput) {
    this.$googleGroupsInitialInputs.find('input[name*="userGoogleGroups"][name*="name"][value]')
      .each((i, el) => {
        $tagsInput.addTag($(el).val())
      });
  }
}

function resetOptions(selectItem) {
  selectItem.find('option').prop('selected', false);
}

export default TagsInput;
