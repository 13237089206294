const DEFAULT_MESSAGE = 'Are you sure you want to continue?';

class ConfirmAlert {
  constructor() {
    this.triggers = document.querySelectorAll('.js-confirm-alert');
    this.selectors = {
      message: 'data-confirm-alert-message'
    }
    this.init();
  }

  init() {
    this.setupListeners();
  }

  setupListeners() {
    Array.from(this.triggers).forEach(trigger =>
      trigger.addEventListener('click', this.listenToTrigger.bind(this))
    )
  }

  listenToTrigger(event) {
    const message = event.target.getAttribute(this.selectors.message)
      || DEFAULT_MESSAGE;
    const isConfirmed = window.confirm(message);

    if (isConfirmed) {
      event.target.removeEventListener('click', this.listenToTrigger);
    } else {
      event.preventDefault();
    }
  }
}

export default ConfirmAlert;
