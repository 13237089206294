const select = {
  copy: '.js-hours-copy',
  group: '.js-hours-group',
  entry: '.js-hours-entry',
};

class ProfileEdit {
  constructor() {
    this.$node = $('.js-profile-edit');
    if (!this.$node.length) {
      return;
    }
    this.init();
  }

  init() {
    this.handleCopyTimeClick();
    this.handlePaymentMethodChange();
  }

  handleCopyTimeClick() {
    const $copyBtn = this.$node.find(select.copy);

    $copyBtn.on('click', event => this.handleCopyTime(event))
  }

  handleCopyTime(event) {
    event.preventDefault();
    const $target = $(event.target);
    const $hoursGroup = $target.closest(select.group);
    const $timeFields = $hoursGroup.find(select.entry)
    const startTime = $timeFields.eq(0).find('input').val();
    const endTime = $timeFields.eq(1).find('input').val();

    $(select.group).not($hoursGroup).each((index, element) => {
      const $hours = $(element).find(select.entry);
      $hours.eq(0).find('input').val(startTime);
      $hours.eq(1).find('input').val(endTime);
    })
  }

  handlePaymentMethodChange() {
    $('.js-payment-method').on('change.payment', event =>
      this.handlePaymentMethod(event.target.value)
    ).trigger('change.payment');
  }

  handlePaymentMethod(paymentMethod) {
    if (paymentMethod === 'paypal') {
      $('.js-payment-method-paypal').removeClass('is-hidden');
      $('.js-payment-method-bank').addClass('is-hidden');
    } else {
      $('.js-payment-method-bank').removeClass('is-hidden');
      $('.js-payment-method-paypal').addClass('is-hidden');
    }
  }
}

export default ProfileEdit;
