import DatatablesCustomPatches from './DatatablesCustomPatches';

class Datatables {
  constructor() {
    this.init();
  }

  init() {
    DatatablesCustomPatches();

    this.peopleTable();
    this.positionsTable();
    this.projectsTable();
    this.invoicesTable();
    this.invoiceItemsTable();
    this.unapprovedInvoiceItemsTable();
    this.approvedInvoiceItemsTable();
    this.vacationsTable();
    this.vacationRequestsTable();
    this.availabilityTable();
  }

  peopleTable() {
    $('.js-people-datatable').DataTable({
      language: {
        info: '<h3 class="subtitle is-5"><strong>_START_ - _END_</strong> of _TOTAL_ people</h3>'
      },
      oLanguage: {
        sLengthMenu: `
          <div class="control is-horizontal">
            <div class="control-label">
              <label class="label">People per page</label>
            </div>
            <span class="select">
              <select>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="-1">All</option>
              </select>
            </span>
          </div>
        `
      },
      fnInitComplete: () => {
        $('.search-data > input').addClass('input').attr("placeholder", "Find person");
        $('#custom-options-target').replaceWith($('#temporary-container ul'));
        $('#temporary-container').remove();

        $('.is-preprocessing, .is-loading').removeClass('is-preprocessing is-loading');
      },
      stateSave: true
    });
  }

  positionsTable() {
    $('.js-positions-datatable').DataTable( {
      fnInitComplete: () => {
        $('.search-data > input').addClass('input').attr("placeholder", "Find position");
        $('#custom-options-target').replaceWith($('#temporary-container ul'));
        $('#temporary-container').remove();
      }
    } );
  }

  projectsTable() {
    $('.js-projects-datatable').DataTable({
      language: {
        info: '<h3 class="subtitle is-5"><strong>_START_ - _END_</strong> of _TOTAL_ projects</h3>'
      },
      oLanguage: {
        sLengthMenu: `
          <div class="control is-horizontal">
            <div class="control-label">
              <label class="label">Projects per page</label>
            </div>
            <span class="select">
              <select>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="-1">All</option>
              </select>
            </span>
          </div>
        `
      },
      fnInitComplete: () => {
        $('.search-data > input').addClass('input').attr("placeholder", "Find project");
        $('#custom-options-target').replaceWith($('#temporary-container ul'));
        $('#temporary-container').remove();

        $('.is-preprocessing, .is-loading').removeClass('is-preprocessing is-loading');
      },
      stateSave: true,
      order: [[ 4, "desc" ]]
    });
  }

  invoicesTable() {
    $('.js-invoices-datatable').DataTable({
      bPaginate: false,
      bInfo: false,
      bFilter: false,
      stateSave: true
    });
  }

  invoiceItemsTable() {
    $('.js-invoice-items-datatable').DataTable( {
      bPaginate: false,
      bInfo: false,
      bFilter: false,
      fnInitComplete: () => {
        $('.search-data > input').addClass('input').attr("placeholder", "Find item");
        $('#custom-options-target').replaceWith($('#temporary-container ul'));
        $('#temporary-container').remove();
        $('.is-preprocessing, .is-loading').removeClass('is-preprocessing is-loading');
      }
    } );
  }

  unapprovedInvoiceItemsTable() {
    $('.js-unapproved-items-datatable').DataTable({
      bPaginate: false,
      bInfo: false,
      bFilter: false,
      fnInitComplete: () => {
        $('.search-data > input').addClass('input').attr("placeholder", "Find item");
        $('#custom-options-target').replaceWith($('#temporary-container ul'));
        $('#temporary-container').remove();
        $('.is-preprocessing, .is-loading').removeClass('is-preprocessing is-loading');
      }
    });
  }

  approvedInvoiceItemsTable() {
    const approved = $('.js-approved-items-datatable').DataTable({
      fnInitComplete: () => {
        $('.search-data > input').addClass('input').attr("placeholder", "Find item");
        $('#custom-options-target').replaceWith($('#temporary-container ul'));
        $('#temporary-container').remove();
        $('.is-preprocessing, .is-loading').removeClass('is-preprocessing is-loading');
      }
    });

    approved.on('search.dt', () => {
      var rows = approved.$('tr', { filter: 'applied' });
      var data = [];

      for (var i = 0; i < rows.length; ++i) {
        data.push(
          parseFloat($('td:nth-child(6)', rows[i]).text().replace(',', ''))
        );
      }

      var sum = data.reduce(function (sum, current) {
        return sum + current;
      }, 0);

      var formattedSum = sum.toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        + ' USD';

      $('.approved-sum').text(formattedSum);
    });

    $('#approved-items_filter input, select[name="approved-items_length"]')
      .addClass('form-control');
  }

  vacationsTable() {
    $('.js-vacations-datatable').DataTable({
      bPaginate: false,
      bInfo: false,
      bFilter: false,
      stateSave: true
    });
  }

  vacationRequestsTable() {
    $('.js-vacation-requests-datatable').DataTable({
      bPaginate: false,
      bInfo: false,
      bFilter: false,
      stateSave: true
    });
  }

  availabilityTable() {
    $('.js-availability').DataTable({
      bPaginate: false,
      bInfo: false,
      bFilter: false,
      fixedHeader: true,
      ordering: false
    });
  }
}

export default Datatables;
