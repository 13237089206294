class Nav {
  constructor() {
    this.navigation = document.querySelector('.js-nav');
    this.toggle = document.querySelector('.js-nav-toggle');

    this.state = {
      open: false,
    }

    if (!this.navigation) {
      return;
    }

    this.init();
  }

  init() {
    this.handleToggleClick();
    this.handleBodyClick();
  }

  handleToggleClick() {
    this.toggle.addEventListener('click', () => {
      this.setState({ open: !this.state.open });
      this.toggleNav();
    });
  }

  handleBodyClick() {
    document.body.addEventListener('click', event => {
      if (event.target === this.toggle || event.target.parentNode === this.toggle) {
        return;
      }
      this.setState({ open: false })
      this.toggleNav();
    });
  }

  toggleNav() {
    if (this.state.open) {
      this.navigation.classList.add('is-active');
    } else {
      this.navigation.classList.remove('is-active');
    }
  }

  setState(newState) {
    this.state = Object.assign({}, this.state, newState);
  }
}

export default Nav;
