import 'tether';
import Drop from 'tether-drop';

class ProfilePhoto {
  constructor() {
    this.init();
  }

  init() {
    if (!document.querySelector('.js-profile-photo')) {
      return;
    }

    let dropInstance = new Drop({
      target: document.querySelector('.js-profile-thumb'),
      content: document.querySelector('.js-profile-photo').innerHTML,
      openOn: 'hover',
      tetherOptions: {
        attachment: 'bottom right',
        targetAttachment: 'top left'
      }
    })
  }
}

export default ProfilePhoto;
