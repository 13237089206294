import Pikaday from 'pikaday';

class Datepicker {
  constructor() {
    this.init();
  }

  init() {
    this.regularDates();
    this.rangeDates();
  }

  regularDates() {
    const dateInputs = document.querySelectorAll(
      'input[type="date"]:not(.js-start-date):not(.js-end-date):not(.js-period-begin):not(.js-period-end)'
    );
    Array.from(dateInputs).forEach(field => {
      new Pikaday({ field });
    });
  }

  rangeDates() {
    const startDateInput = document.querySelector('input[type="date"].js-start-date');
    const endDateInput = document.querySelector('input[type="date"].js-end-date');

    if (startDateInput && endDateInput) {
      const startDate = new Pikaday({
        field: startDateInput,
        onSelect: function() {
          endDate.setMinDate(this.getDate());
          endDate.gotoDate(this.getDate());
        }
      });

      const endDate = new Pikaday({
        field: endDateInput,
        onSelect: function() {
          startDate.setMaxDate(this.getDate());
        }
      });
    }

  }
}

export default Datepicker;
