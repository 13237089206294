class ProjectsEdit {
  constructor() {
    this.$node = $('.js-projects-edit');

    if (!this.$node.length) {
      return;
    }

    this.init();
  }

  init() {
    this.setDefaultRepository();
  }

  // Symfony 2 cannot into setting default select option
  setDefaultRepository() {
    this.$node.find('[data-default]')
      .each((index, element) => $(element).val($(element).data('default')));
  }
}

export default ProjectsEdit;
