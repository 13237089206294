// global from Twig template: limitedPeriodStartDate, limitedPeriodEndDate, updatedAt
import Pikaday from 'pikaday';

class Availability {
  constructor() {
    this.availability = document.querySelector('.js-availability');

    if (!this.availability) {
      return;
    }

    this.init();
  }

  init() {
    this.rememberWorkspace();
    this.handleAvailabilityItem();
    this.handleAvailabilityUpdates();
    this.handleSearch();
    this.handleTypeChange();
  }

  rememberWorkspace() {
     $(".js-view-weeks").on('change', function () {
      Cookies.set('availability-view-weeks', $(this).val(), { expires: 365 });
      window.location = window.location.pathname;
    });
  }

  handleAvailabilityItem() {
    $('.js-availability-item').on('click', function (e) {
      var $this = $(this);
      var template = $('.js-availability-item-template')
      var wrapper = $this.next('.js-availability-item-wrapper');

      wrapper.html(template.html());
      wrapper.find('.user-id').val($this.data('user-id'));
      wrapper.find('h2 span').text($this.data('user-display-name'));
      wrapper.toggleClass('is-hidden');

      wrapper.find('.user-id').parents('.js-user-select').css({'visibility': 'hidden', 'position': 'absolute'})

      var periodBegin = new Pikaday({
        field: document.querySelector('input[type="date"].js-period-begin:not(.js-limited-period)'),
        onSelect: function() {
          periodEnd.setMinDate(this.getDate());
        }
      });

      var periodEnd = new Pikaday({
        field: document.querySelector('input[type="date"].js-period-end:not(.js-limited-period)'),
        onSelect: function() {
          periodBegin.setMaxDate(this.getDate());
        }
      });

      var periodBeginLimited = new Pikaday({
        field: document.querySelector('input[type="date"].js-period-begin.js-limited-period'),
        minDate: new Date(limitedPeriodStartDate),
        maxDate: new Date(limitedPeriodEndDate),
        onselect: function() {
          periodEndLimited.setMinDate(this.getDate());
        }
      });

      var periodEndLimited = new Pikaday({
        field: document.querySelector('input[type="date"].js-period-end.js-limited-period'),
        minDate: new Date(limitedPeriodStartDate),
        maxDate: new Date(limitedPeriodEndDate),
        onselect: function() {
          periodBeginLimited.setMaxDate(this.getDate());
        }
      });

      $('.js-availability-item-wrapper').not(wrapper).addClass('is-hidden');

      $('.js-close-availability-item').on('click', function (e) {
        var $this = $(this);
        $this.parents('.js-availability-item-wrapper').addClass('is-hidden');
        e.preventDefault();
      });

      wrapper.find('form').on('submit', function (e) {
          wrapper.find('.form-group.has-error').removeClass('has-error');

          var inputs = ['.js-title', '.js-period-begin', '.js-period-end'];
          var valid = true;
          for (var i = 0; i < inputs.length; ++i) {
              var input = wrapper.find(inputs[i]);
              input.removeClass('is-danger');
              var val = input.val().trim();

              if (val.length == 0) {
                  valid = false;
                  input.addClass('is-danger');
              }
          }

          if (!valid) {
            e.preventDefault();
          }
      });

      e.preventDefault();
    });
  }

  handleAvailabilityUpdates() {
    var startRefreshCounter = function (seconds) {
        var seconds = seconds || 60;
        var counter = $('.availability-updated-alert .seconds');

        var counterTextForSeconds = function (seconds) {
            return seconds == 1 ? (seconds + " second") : (seconds + " seconds");
        };

        var updateCounter = function (text) {
            counter.text(text);
        }

        updateCounter(counterTextForSeconds(seconds--));

        var counterId = setInterval(function () {
            updateCounter(counterTextForSeconds(seconds--));

            if (seconds < 0) {
                clearInterval(counterId);
                window.location = window.location.pathname;
            }
        }, 1000);

        $('.availability-updated-alert').removeClass('is-hidden');

        $('.availability-updated-alert .delete').click(function (ev) {
            clearInterval(counterId);
            $('.availability-updated-alert').addClass('is-hidden');
            startCheckingAvailability();
            ev.preventDefault();
        });
    };

    var startCheckingAvailability = function () {
        console.log('started checking for availability updates')
        var updateCheckedId = setInterval(function () {
            $.get('updated_at', function (data) {
                var lastUpdatedAt = data.updated_at;

                if (lastUpdatedAt > updatedAt) {
                    console.log('there were updates to availability')
                    updatedAt = lastUpdatedAt;
                    clearInterval(updateCheckedId);
                    startRefreshCounter();
                } else {
                    console.log('no updates to availability')
                }
            });
        }, 90000);
    };

    startCheckingAvailability();
  }

  handleSearch() {
    var $search = $('.js-search-items');
    var $table = $('.js-availability');
    $search.on('input', function () {
        var queryTerms = $search.val().trim().toLowerCase().split(' ');
        queryTerms.forEach(function (query) {
          $table.find('tbody tr').each(function () {
              var found = false;
              var $tr = $(this);
              $tr.find('[data-search-keyword]').each(function () {
                  var keywords = $(this).data('search-keyword').toLowerCase();
                  if (keywords.includes(query)) {
                      found = true;
                      return true;
                  }
              });
              if (found) {
                  $tr.removeClass('is-hidden');
              } else {
                  $tr.addClass('is-hidden');
              }
          });
        })
    });
  }

  handleTypeChange() {
    var wrapper = $('.js-availability-item-wrapper');
    var $defaultValues = [];

    $('.js-type:first option').each(function (index, value) {
      $defaultValues.push( $(value).text() );
    });

    wrapper.on('change', '.js-type', function () {
      var $title = $(this).parents('.js-availability-item-wrapper').find('.js-title');

      if ($.inArray($title.val(), $defaultValues) !== -1) {
        $title.val( $(this).find('option:selected').text() );
      }
    });
  }
}

export default Availability;
