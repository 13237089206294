/*
 * Creating custom radios and checkboxes for js-custom-radios output.
 * DOM output from Symfony:
 *
 * <div class="js-custom-radios" data-hints="[...]" data-labels="[...]">
 *   <input type="radio">
 * </div>
 *
 * CustomRadios output:
 * <div class="js-custom-radios" data-hints="[...]" data-labels="[...]">
 *   <div class="custom-radio">
 *     <input type="radio" id="...">
 *     <label for="..." title="a hint">label</label>
 *   </div>
 * </div>
 */

class CustomRadios {
  constructor() {
    this.class = {
      container: 'js-custom-radios'
    };
    this.containers = document.querySelectorAll('.' + this.class.container);

    if (!this.containers) {
      return;
    }

    this.init();
  }

  init() {
    Array.from(this.containers).forEach(container =>
      this.createCustomRadios(container)
    );
  }

  createCustomRadios(container) {
    const radios = container.querySelectorAll('input');
    const labels = JSON.parse(container.dataset.labels);
    const hints = JSON.parse(container.dataset.hints);
    let customRadios = document.createDocumentFragment();

    Array.from(radios).forEach((radio, index) =>
      this.wrapSingleRadio(radio, index, labels, hints, customRadios)
    );

    this.replaceRadiosWithCustom(container, customRadios);
  }

  wrapSingleRadio(radio, index, labels, hints, customRadios) {
    const wrapper = this.createRadioWrapper();

    this.fillWrapper(wrapper, labels, hints, radio, index);
    customRadios.appendChild(wrapper);
  }

  fillWrapper(wrapper, labels, hints, radio, index) {
    const innerLabel = this.createRadioLabel(labels, hints, radio, index);

    wrapper.appendChild(radio);
    wrapper.appendChild(innerLabel);
  }

  createRadioWrapper() {
    const wrapper = document.createElement('div');

    wrapper.setAttribute('class', 'custom-radio');

    return wrapper;
  }

  createRadioLabel(labels, hints, radio, index) {
    const innerLabel = document.createElement('label');

    innerLabel.textContent = labels[index];
    innerLabel.setAttribute('title', hints[index]);
    innerLabel.setAttribute('class', 'button is-light');
    innerLabel.setAttribute('for', radio.id);

    return innerLabel;
  }

  replaceRadiosWithCustom(container, customRadios) {
    container.innerHTML = '';
    container.appendChild(customRadios);
  }
}

export default CustomRadios;
